import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: { _affiliate_description: { regex: "/.*[kK]äng.*/" } }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "skosnören-till-kängor"
    }}>{`Skosnören Till Kängor`}</h1>
    <p>{`När du är på jakt efter skosnören till kängor så är det tre saker du behöver
tänka på. Längd, form och kvalitet. Kängor är i regel högre än vanliga
skor och du kan därför behöva extra långa skosnören. När det gäller formen
så är det bra att satsa på runda skosnören. Detta då runda ofta är mer
slitstarka vilket också leder till att de går att knyta hårdare. Sist men inte minst så
gäller det att köpa skosnören med bra kvalitet. Oavsett om det är till
dina vandringskängor eller arbetskängor så vill du inte att skosnörena ska
gå sönder när du minst anar det. Som extra säkerhet är det alltid bra att ha ett par extra skosnören liggandes, antingen hemma i garderoben eller i vandringsryggsäcken.`}</p>
    <p>{`Utöver det vi har nämnt ovan så kan det också vara bra att ta en titt på hålen
i dina kängor. Har du små hål är risken att du råkar köpa för tjocka skosnören som inte passar. Men i regel har
kängor ganska grova hål så det borde inte vara några problem, men det kan ändå
vara värt att dubbelkolla då skosnören till kängor brukar vara extra grova.`}</p>
    <h2 {...{
      "id": "hur-långa-skosnören-till-kängor"
    }}>{`Hur långa skosnören till kängor?`}</h2>
    <p>{`Vilken längd ska man ha då? Enklast är att helt enkelt mäta längden på de
skosnörena du har på dina kängor idag, och köpa ett par som är lika långa. Detta är ett relativt felsäkert sätt.
Ibland har man dock inte möjlighet, om du tillexempel redan slängt dina gamla eller hittat ett par gamla kängor på vinden som helt enkelt saknade skosnören. För dessa tillfällen har
vi skapat en guide under `}<Link to="/hur-langa-skosnoren/" mdxType="Link">{`Hur Långa Skosnören?`}</Link>{`. På denna guide kan du med hjälp av antalet hål för skosnören på dina kängor tillsammans med en ungefärlig bredd räkna ut hur långa skosnören du bör köpa. Du kan sedan här
på denna sida filtrera efter rätt längd.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>
    <h2 {...{
      "id": "tänk-på-kvaliteten"
    }}>{`Tänk på kvaliteten`}</h2>
    <p>{`Skulle ett par skosnören till dina vardagsskor börja bli slitna och gå sönder är det inte hela världen. Ofta är det inga problem att ta sig hem utan att knyta skorna eller ta sig in på en butik och köpa nya.
Är du däremot ute på en 7 dagar lång vandring i fjällen är det viktigt att du kan lita på dina kängor och dess skosnören.
Tänk därför på att kanske lägga lite extra pengar för att få ett par skosnören som är gjorda för just kängor och därmed kommer klara de påfrestningar som de utsätts för. Även skosnören till kängor som används till mer vardagsbruk tenderar att slitas mer än vanga skosnören. Detta då de ofta knyts lite hårdare och utsätts för lite tuffare klimat så som regn, snö, slask och kyla. Skulle dina skosnören gå sönder på din vandringstur och du inte har några extra så finns det några knep:`}</p>
    <ul>
      <li parentName="ul">{`Offra lite tältlina och gör ett skosnöre av det. Ofta har man ganska många, och långa, tältlinor och man klarar sig även om ett blir lite kortare`}</li>
      <li parentName="ul">{`Klipp av ditt andra, hela skosnöre, på mitten och snöra sedan vartannat hål. Snörar du vartannat hål klarar du dig på ett halv skosnöre på varje känga, även om det inte kommer sitta riktigt lika bra.`}</li>
      <li parentName="ul">{`Ta delarna från det trasiga skosnöret och knyt korta stumpar vid varje hålpar. Det blir många knutar, men du kommer kunna fortsätta din tur.`}</li>
    </ul>
    <p>{`Men bäst är så klart att alltid ha ett par extra skosnören, tvättlina eller tältlina. Linor och snören kommer nästan alltid till nytta oavsett om det är tältet, skorna eller ryggsäcken som strular. Ha för vana att alltid ha lite extra, förslagsvis i din första hjälpen-påse.`}</p>
    <h2 {...{
      "id": "paracord"
    }}>{`Paracord`}</h2>
    <p>{`Många väljer även att helt skippa klassika skonören och istället knyta skorna med så kallad Paracord. Paracord är ursprunligen fallskärmslina som numera har blivit väldigt vanlig bland friluftsmänniskor. Att ha en rulle Paracord i ryggsäcken är ett utmärkt sätt att lösa divese problem som kan dyka upp. Ett trasigt skosnöre, en rem på ryggsäcken, skärmep eller något annat som går sönder kan tillfälligt ersättas med paracord.`}</p>
    <h2 {...{
      "id": "god-tur"
    }}>{`God tur!`}</h2>
    <p>{`Hoppas att vår guide har kunna hjälpa dig i ditt köp av nya skosnören till
dina kängor. Att hitta rätt par av hög kvalitet som passar till just dina
kängor kan rädda din fjällsemester då du annars kanske hade riskerat gå
halva vandringen med trasiga skosnören eller hjälpa dig från att gå i dina sommarskor den dagen då snön plötsligt faller ner utanför fönstret.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      